import { BaseModel } from 'modules/core/models/base-model';

export class SpecificationRevision extends BaseModel {
    id               = null;
    specification_id = null;
    revision_number  = null;
    edition_number   = null;
    code             = null;
    effective_date   = null;

    listings  = [];
    bulletins = [];
}
