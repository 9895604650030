import { BaseModel } from 'modules/core/models/base-model';

export class LotIntervention extends BaseModel {
    designation              = null;
    code                     = null;
    intervention_type_id     = null;
    lot_intervention_type_id = null;
    number                   = null;
    geosig_phase_id          = null;
    make_geosig_available_id = null;
    start_date               = null;
    finish_date              = null;
    closed_id                = null;
}
