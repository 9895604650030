import { BaseModel } from 'modules/core/models/base-model';

export class SpecificationRevisionMaterialType extends BaseModel {
    id                        = null;
    specification_revision_id = null;
    sector                    = null;

    material_types = [];
    sectors        = [];
}
