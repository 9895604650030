import { BaseModel } from 'modules/core/models/base-model';

export class Listing extends BaseModel {
    id            = null;
    number        = null;
    designation   = null;
    concession_id = null;
    sector_id     = null;
    observations  = null;
}
