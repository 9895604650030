import { BaseModel } from 'modules/core/models/base-model';

export class ReportHeader extends BaseModel {
    title          = null;
    code           = null;
    model          = null;
    revision       = null;
    date           = null;
    effective_date = null;
    observations   = null;
    image_file_id  = null;
    category_id    = null;
}
