import { BaseModel } from 'modules/core/models/base-model';

const LOT          = 1,
      INTERVENTION = 2;

export class LotInterventionType extends BaseModel {
    static get LOT() {
        return LOT;
    }

    static get INTERVENTION() {
        return INTERVENTION;
    }
}
