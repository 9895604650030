import { BaseModel } from 'modules/core/models/base-model';

export class LotConstruction extends BaseModel {
    designation              = null;
    number                   = null;
    code                     = null;
    pk_s                     = null;
    pk_e                     = null;
    element_id               = null;
    lot_construction_type_id = null;
    lot_intervention_id      = null;
    inspection_class_id      = null;
    entity_id                = null;

    elements   = [];
    design_ids = [];
}
