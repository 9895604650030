import { BaseModel } from 'modules/core/models/base-model';

export class ManageStudy extends BaseModel {
    id             = null;
    code           = null;
    especification = null;
    observations   = null;

    manage_fields = [];
}
