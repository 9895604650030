import { BaseModel } from 'modules/core/models/base-model';

export class ListingRevision extends BaseModel {
    id              = null;
    listing_id      = null;
    revision_number = null;
    effective_date  = null;
    closed_date     = null;

    bulletins = [];
}
