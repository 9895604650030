import { BaseModel } from 'modules/core/models/base-model';

export class OEData extends BaseModel {
    id                  = null;
    lot_construction_id = null;
    pks_s               = null;
    pks_f               = null;
    pkf_s               = null;
    pkf_f               = null;
    pk_type_id          = null;
    pk_type_code        = null;
    number_of_lanes     = null;
    axis_id             = null;
    branch_id           = null;
    edge_id             = null;
    code                = null;
}
