import { BaseModel } from 'modules/core/models/base-model';

const OE = 1,
      OA = 2,
      OH = 3;

export class LotConstructionType extends BaseModel {
    static get OE() {
        return OE;
    }

    static get OA() {
        return OA;
    }

    static get OH() {
        return OH;
    }
}
