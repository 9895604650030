import { BaseModel } from 'modules/core/models/base-model';

export class LotInterventionLotConstruction extends BaseModel {
    lot_intervention_id      = null;
    lot_construction_id      = null;
    lot_construction_type_id = null;
    user_entity_team_lot_id  = null;
    lot_construction_ids     = [];
    user_entity_team_lot_ids = [];
}
